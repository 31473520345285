import $ from 'jquery';
import '../vendors/magnific-popup';

$('.js-open-video').magnificPopup({
	autoFocusLast: false,
	type: 'iframe',
	iframe: {
		markup: '<div class="mfp-iframe-scaler mfp-with-anim">'+
					'<div class="mfp-close"></div>'+
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>'+
				'</div>',
		patterns: {
			youtube: {
				index: 'youtube.com/',
				id: 'v=',
				src: 'https://www.youtube.com/embed/%id%?autoplay=1'
			}
		}
	},
});
