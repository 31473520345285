import 'svgxuse';
import $ from 'jquery';
import offcanvas from 'fjaka/js/components/offcanvas';
import hamburgerIcon from 'fjaka/js/components/hamburger-icon';
import './components/fullpage-slider';
import './components/info-slider';
import './components/offcanvas-nav';
import './components/popup';

offcanvas( $ );
hamburgerIcon( $ );
