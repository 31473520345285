import $ from 'jquery';
import '../vendors/scrolloverflow';
import '../vendors/fullpage';

$('.c-fullpage-slider').fullpage({
	licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
	sectionSelector: '.c-fullpage-slider__section',
	easingcss3: 'cubic-bezier(0.22,0.44,0,1)',
	scrollingSpeed: 1000,
	scrollOverflow: true,
	fixedElements: 'c-site-header, .c-site-foter',
	anchors: ['home', 'upcoming', 'about', 'talks', 'startuptalk', 'testimonials'],
	menu: '.c-site-header__nav__list',
});
