import $ from 'jquery';
import '../vendors/slick';

$('.c-info-slider').slick({
	slidesToShow: 6,
	autoplay: true,
	arrows: false,
	rows: 0,
	responsive: [
		{
		  breakpoint: 1800,
		  settings: {
			slidesToShow: 5,
		  }
		},
		{
		  breakpoint: 1450,
		  settings: {
			slidesToShow: 4,
		  }
		},
		{
		  breakpoint: 1240,
		  settings: {
			slidesToShow: 3,
		  }
		},
		{
		  breakpoint: 992,
		  settings: {
			slidesToShow: 2,
		  }
		},
		{
		  breakpoint: 576,
		  settings: {
			slidesToShow: 1,
		  }
		}
	]
});
